import React, { useState } from 'react';
import { Container, Flex, Box, Card, css, Spinner, Select, Button, Text } from 'theme-ui';
import { TextMask } from 'react-text-mask-hoc';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Reveal from '@solid-ui-components/Reveal';
import Divider from '@solid-ui-components/Divider';
import ListItem from '@solid-ui-components/ListItem';
import FlexImage from '@solid-ui-components/FlexImage';
import FlexContent from '@solid-ui-components/FlexContent';
import FlexOverlapFade from '@solid-ui-components/FlexOverlapFade';
import ContentText from '@solid-ui-components/ContentText';
import ContentImages from '@solid-ui-components/ContentImages';
import Icon from '@solid-ui-components/ContentIcon';
import { textContent } from '../textContent';
import FormInput from '@solid-ui-components/ContentForm/FormInput';
import FormTextarea from '@solid-ui-components/ContentForm/FormTextarea';
import { BiCheckCircle, BiErrorCircle } from 'react-icons/bi';
import envelopeIcon from '../../../../../../site/content/assets/flexiblocks/icons/bx-envelope.svg';
import userIcon from '../../../../../../site/content/assets/flexiblocks/icons/bx-user.svg';
import companyIcon from '../../../../../../site/content/assets/flexiblocks/icons/bx-building-house.svg';
import chatIcon from '../../../../../../site/content/assets/flexiblocks/icons/bx-chat.svg';
import phoneIcon from '../../../../../../site/content/assets/flexiblocks/icons/bx-phone.svg';
import FormCheckbox from '@solid-ui-components/ContentForm/FormCheckbox';
import { apiSupport } from '../../../../../../site/api/api-support';

const formatPhoneNumber = phone => {
    const digits = phone.replace(/\D/g, '');
    const formattedPhone = `+55${digits}`;
    return formattedPhone;
};

const PhoneInput = props => (
    <TextMask
        icon={{
            src: phoneIcon,
            color: 'alpha',
        }}
        guide={false}
        type="TEL"
        name={'phone'}
        maxLength="20"
        id={'phone'}
        placeholder={{ text: 'Insira seu celular' }}
        mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        Component={FormInput}
        value={props.value}
        onChange={props.onChange}
        touched={props.touched}
        onBlur={props.onBlur}
    />
);

const styles = {
    form: {
        position: `relative`,
        width: ['85%', '100%'],
        margin: '0 auto',
    },
    responseOverlay: {
        position: `absolute`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `transparent`,
        width: `full`,
        height: `105%`,
        transition: `background-color 350ms ease-in`,
        textAlign: `center`,
        zIndex: -1,
        p: 2,
        top: 0,
        left: 0,
        active: {
            zIndex: 0,
            backgroundColor: `rgba(255,255,255,0.85)`,
        },
    },
};

const SupportContent = () => {
    const [formValues, setFormValues] = useState({
        formState: {
            email: '',
            name: '',
            company_name: '',
            phone: '',
            contact_type: '',
            message: '',
            data_sharing_consent: false,
            whatsapp_opt_in: false,
        },
        isSubmitting: false,
        success: false,
        hasError: false,
        errorMessage: '',
        successMessage: '',
    });

    const { isSubmitting, success, hasError, errorMessage, successMessage } = formValues;

    const successHandler = (response, email) => {
        setFormValues({
            ...formValues,
            isSubmitting: false,
            success: true,
            hasError: false,
            successMessage: `Mensagem enviada com sucesso! Em breve retornaremos o contato. Seu protocolo é: ${response.data.protocol_number}`,
        });
        try {
            window.lintrk('track', { conversion_id: 13755321 });
        } catch (error) {
            console.log(error);
        }
    };

    const errorHandler = error => {
        setFormValues({
            ...formValues,
            isSubmitting: false,
            success: false,
            hasError: true,
            errorMessage: 'Ocorreu um erro. Por favor, tente novamente.',
        });
        console.log(error);
    };

    const submitForm = formData => {
        let contactMessage = {
            name: formData.name,
            company: formData.company_name,
            email: formData.email,
            phone: formatPhoneNumber(formData.phone),
            contact_type: formData.contact_type,
            message: formData.message,
            whatsapp_opt_in: formData.whatsapp_opt_in,
            data_sharing_consent: formData.data_sharing_consent,
        };

        setFormValues({ ...formValues, isSubmitting: true });

        apiSupport
            .sendSupportTicket(contactMessage)
            .then(res => successHandler(res, formData.email))
            .catch(errorHandler);
    };

    const renderForm = ({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
        return (
            <>
                <form css={css(styles.form)} onSubmit={handleSubmit} noValidate>
                    <Box variant="forms.row">
                        <Box key={`inputs-register`} variant={'forms.compact'}>
                            <Box my={2} sx={errors?.name ? { border: '2px solid #d9534f', borderRadius: '35px' } : { border: '2px solid transparent' }}>
                                <FormInput
                                    autoFocus
                                    maxLength="300"
                                    icon={{ src: userIcon, color: 'alpha' }}
                                    type="TEXT"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.name}
                                    value={values.name}
                                    name={'name'}
                                    id={'name'}
                                    placeholder={{ text: 'Nome (ex.: Ruy Barbosa)' }}
                                />
                            </Box>
                            <Box my={2} sx={errors?.company_name ? { border: '2px solid #d9534f', borderRadius: '35px' } : { border: '2px solid transparent' }}>
                                <FormInput
                                    maxLength="60"
                                    icon={{ src: companyIcon, color: 'alpha' }}
                                    type="TEXT"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.company_name}
                                    value={values.company_name}
                                    name={'company_name'}
                                    id={'company_name'}
                                    placeholder={{ text: 'Escritório/Empresa' }}
                                />
                            </Box>
                            <Box my={2} sx={errors?.email ? { border: '2px solid #d9534f', borderRadius: '35px' } : { border: '2px solid transparent' }}>
                                <FormInput
                                    maxLength="320"
                                    icon={{ src: envelopeIcon, color: 'alpha' }}
                                    type="TEXT"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.email}
                                    value={values.email}
                                    name={'email'}
                                    id={'email'}
                                    placeholder={{ text: 'Insira seu e-mail' }}
                                />
                            </Box>
                            <Box my={2} sx={errors?.phone ? { border: '2px solid #d9534f', borderRadius: '35px' } : { border: '2px solid transparent' }}>
                                <PhoneInput onChange={handleChange} onBlur={handleBlur} touched={touched.phone} value={values.phone} />
                            </Box>
                            <Box
                                my={2}
                                sx={
                                    errors?.contact_type
                                        ? { border: '2px solid #d9534f', borderRadius: '35px', m: '0', width: 'full' }
                                        : { border: '2px solid transparent', m: '0', width: 'full' }
                                }>
                                <Box
                                    sx={{
                                        display: `flex`,
                                        alignItems: `center`,
                                        bg: `omegaLighter`,
                                        borderRadius: `35px`,
                                        width: `full`,
                                    }}>
                                    <Icon content={{ src: envelopeIcon, color: 'alpha' }} size="xs" round ml="3" pl="2" />
                                    <Select
                                        sx={{
                                            border: 'none',
                                            outline: 'none',
                                            width: '100vw',
                                            maxWidth: '100%',
                                            fontFamily: 'Poppins',
                                            '::placeholder': {
                                                color: '#2d3748',
                                            },
                                        }}
                                        name="contact_type"
                                        id="contact_type"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        touched={touched.contact_type}
                                        value={values.contact_type}>
                                        <option value="select">Selecione o motivo do contato</option>
                                        <option value="Imprensa">Imprensa</option>
                                        <option value="Suporte">Suporte</option>
                                        <option value="Dúvida sobre planos">Dúvida sobre planos</option>
                                        <option value="Quero iniciar um teste">Quero iniciar um teste</option>
                                        <option value="Quero contratar um plano">Quero contratar um plano</option>
                                        <option value="Outros">Outros</option>
                                    </Select>
                                </Box>
                            </Box>
                            <Box my={2} sx={errors?.message ? { border: '2px solid #d9534f', borderRadius: '30px' } : { border: '2px solid transparent' }}>
                                <FormTextarea
                                    maxLength="1024"
                                    sx={{ resize: 'none' }}
                                    icon={{ src: chatIcon, color: 'alpha' }}
                                    type="TEXTAREA"
                                    name={'message'}
                                    id={'message'}
                                    value={values.message}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.message}
                                    placeholder={{ text: 'Descrição' }}
                                />
                            </Box>
                            <Box
                                my={2}
                                py={2}
                                sx={errors?.data_sharing_consent ? { border: '2px solid #d9534f', borderRadius: '35px' } : { border: '2px solid transparent' }}>
                                <FormCheckbox
                                    placeholder={{ text: 'Concordo em compartilhar estas informações para fins de suporte e melhoria do serviço.' }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.data_sharing_consent}
                                    value={values.data_sharing_consent}
                                    name={'data_sharing_consent'}
                                    id={'data_sharing_consent'}
                                />
                            </Box>
                            <Box my={2} py={2} sx={errors?.whatsapp_opt_in ? { border: '2px solid #d9534f', borderRadius: '35px' } : { border: '2px solid transparent' }}>
                                <FormCheckbox
                                    placeholder={{ text: 'Autorizo o contato via WhatsApp para acompanhar minha solicitação ou fornecer informações relevantes.' }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.whatsapp_opt_in}
                                    value={values.whatsapp_opt_in}
                                    name={'whatsapp_opt_in'}
                                    id={'whatsapp_opt_in'}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box my={2} sx={{ textAlign: `center` }}>
                        <Button variant="secondary" type="submit">
                            ENVIAR
                        </Button>
                    </Box>
                    <Box sx={styles.responseOverlay} css={(isSubmitting || success || hasError) && styles.responseOverlay.active}>
                        {isSubmitting && (
                            <Reveal effect="fadeInDown">
                                <Spinner size="64" color="alpha" />
                            </Reveal>
                        )}
                        {success && (
                            <Reveal effect="fadeInDown">
                                <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: '0.75rem' }}>
                                    <Text variant="medium">{successMessage}</Text>
                                    <BiCheckCircle size="64" css={css({ color: `success` })} />
                                    <Button variant="secondary" onClick={() => (window.href = 'https://juit.io')}>
                                        Voltar para o início
                                    </Button>
                                </Flex>
                            </Reveal>
                        )}
                        {hasError && (
                            <Reveal effect="fadeInDown">
                                <Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: '0.75rem' }}>
                                    <Text variant="medium">{errorMessage}</Text>
                                    <BiErrorCircle size="64" css={css({ color: `error` })} />
                                    <Button variant="secondary" onClick={() => setFormValues({ ...formValues, hasError: false })}>
                                        Tentar novamente
                                    </Button>
                                </Flex>
                            </Reveal>
                        )}
                    </Box>
                </form>
            </>
        );
    };

    return (
        <Container>
            <Flex
                sx={{
                    alignItems: [`space-between`],
                    justifyContend: 'center',
                    flexDirection: [`column-reverse`, `row`],
                    mx: [null, null, null, null],
                }}>
                <Box sx={{ textAlign: ['center', 'left'] }}>
                    <ContentText content={textContent} />
                    <Formik
                        initialValues={formValues.formState}
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={submitForm}
                        render={renderForm}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Por favor, insira um e-mail válido').required('Nós precisamos do seu email para liberar seu acesso a plataforma'),
                            name: Yup.string().trim().required('Por favor, preencha seu nome'),
                            company_name: Yup.string().trim().required('Por favor, preencha o nome da empresa'),
                            phone: Yup.string().required().trim().min(14),
                            contact_type: Yup.string()
                                .required('Por favor, selecione o motivo do contato')
                                .oneOf(['Imprensa', 'Suporte', 'Dúvida sobre planos', 'Quero iniciar um teste', 'Quero contratar um plano', 'Outros']),
                            message: Yup.string().trim().required('Por favor, insira uma mensagem'),
                            data_sharing_consent: Yup.boolean().oneOf([true], 'Você deve aceitar os termos e condições.').required('Campo obrigatório'),
                            whatsapp_opt_in: Yup.boolean().required('Campo obrigatório'),
                        })}
                    />
                </Box>
            </Flex>
            <FlexOverlapFade direction={'rtl'} />
        </Container>
    );
};

export default SupportContent;
