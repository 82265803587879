export const textContent = [
    { text: 'Suporte', variant: 'h4', mb: 4 },
    {
        text: 'Solicite suporte no formulário abaixo.',
        variant: 'p',
    },
    {
        text: 'Nossa equipe fará uma análise dos dados fornecidos e entrará em contato.',
        variant: 'p',
    },
];

export default textContent;
