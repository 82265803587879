import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://api.juit.io:8080/v1/site',
    timeout: 12 * 1000,
});

export const apiSupport = Object.freeze({
    sendSupportTicket: function (payload) {
        return axiosInstance.post('/contacts', payload);
    },
});
